import { SET_TRANSACTION, SET_PAYMENT_STATUS } from "./types";
import { AuthInstance } from "../../utils/axiosConfig";
import { setErrors } from "../Error/actions";
import { resetErrors } from "../Error/epics";
import { setLoading } from "../Common/actions";
import { setCurrentCertificateFlowStep } from "../../store/Certificate/epics";
import { orderType } from "../../lookups/status";

export const setPaymentStatus = (payload) => ({
  type: SET_PAYMENT_STATUS,
  payload: payload,
});

export const setTransaction = (payload) => ({
  type: SET_TRANSACTION,
  payload: payload,
});

export const callCreateTransaction = (navigate, payload, companyId) => {
  return async (dispatch) => {
    try {
      dispatch(resetErrors());
      dispatch(setLoading(true));
      let response = await AuthInstance.post(
        `payments/pay_assessment_order/`,
        payload
      );
      let data = await response.data;
      if (data.payload.data.checkout_id) {
        dispatch(setTransaction(data.payload.data));

        dispatch(setLoading(false));

        if (payload.payment_method === "SADAD") {
          navigate(
            `/${companyId}/payment/${payload.assessment_order_id}/method/sadad/invoice?type=${orderType.certificate}`
          );
        } else {
          navigate(
            `/${companyId}/payment/${payload.assessment_order_id}/method/${payload.payment_method}/details?type=${orderType.certificate}`
          );
        }
      } else {
        dispatch(setLoading(false));
      }
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(setErrors(error));
    }
  };
};

export const callUpdateTransactionStatus = (
  navigate,
  payload,
  companyId,
  type
) => {
  return async (dispatch) => {
    try {
      dispatch(resetErrors());

      let response = await AuthInstance.post(`payments/check_status/`, {
        checkout_id: payload.checkoutId,
      });
      let data = await response.data;

      if (data.payload.data.status === "PENDING") {
        dispatch(
          callUpdateTransactionStatus(navigate, payload, companyId, type)
        );
      } else if (data.payload.data.status === "SUCCESS") {
        if (type === orderType.certificate) {
          dispatch(setCurrentCertificateFlowStep(1.5));
        }
        navigate(
          `/${companyId}/payment/${payload.uuid}/method/${payload.paymentMethod}/successful?type=${type}`
        );
      } else if (data.payload.data.status === "REJECTED") {
        navigate(
          `/${companyId}/payment/${payload.uuid}/method/${payload.paymentMethod}/faild?type=${type}`
        );
      }
    } catch (error) {
      // dispatch(setLoading(false))
      dispatch(setErrors(error));
    }
  };
};

export const callCreateBankTransaction = (
  navigate,
  payload,
  companyId,
  uuid
) => {
  return async (dispatch) => {
    try {
      dispatch(resetErrors());
      dispatch(setLoading(true));
      let response = await AuthInstance.post(
        `payments/pay_assessment_order/`,
        payload
      );
      let data = await response.data;
      dispatch(setTransaction(data.payload.data));
      dispatch(setLoading(false));
      navigate(
        `/${companyId}/payment/${uuid}/method/transfer/status?status=PENDING_BANK_TRANSFER_PAYMENT&type=${orderType.certificate}`
      );
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(setErrors(error));
    }
  };
};

export const callCreateTrainingBankTransaction = (
  navigate,
  payload,
  companyId,
  uuid
) => {
  return async (dispatch) => {
    try {
      dispatch(resetErrors());
      dispatch(setLoading(true));
      let response = await AuthInstance.post(
        `payments/pay_training_requisition/`,
        payload
      );

      let data = await response.data;
      dispatch(setTransaction(data.payload.data));
      dispatch(setLoading(false));
      navigate(
        `/${companyId}/payment/${uuid}/method/transfer/status?status=PENDING_BANK_TRANSFER_PAYMENT&type=${orderType.training}`
      );
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(setErrors(error));
    }
  };
};

export const callCreateTrainingTransaction = (navigate, payload, companyId) => {
  return async (dispatch) => {
    try {
      dispatch(resetErrors());
      dispatch(setLoading(true));
      let response = await AuthInstance.post(
        `payments/pay_training_requisition/`,
        payload
      );
      let data = await response.data;
      if (data.payload.data.checkout_id) {
        dispatch(setTransaction(data.payload.data));
        dispatch(setLoading(false));
        if (payload.payment_method === "SADAD") {
          navigate(
            `/${companyId}/payment/${payload.training_requisition_id}/method/sadad/invoice?type=${orderType.training}`
          );
        } else {
          navigate(
            `/${companyId}/payment/${payload.training_requisition_id}/method/${payload.payment_method}/details?type=${orderType.training}`
          );
        }
      } else {
        dispatch(setLoading(false));
      }
    } catch (error) {
      dispatch(setLoading(false));
      dispatch(setErrors(error));
    }
  };
};
